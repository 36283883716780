import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { Button, Loading, Typography } from "ui-library/atoms";
import { Box } from "ui-library/layouts";
import { DataTable } from "ui-library/modules";

import i18n from "core/i18n";
import { CancelOrCloseRequestButton } from "containers/inPageForm";
import { DocumentLink } from "containers/DocumentLink";
import { useHasRole } from "core/hooks";
import { Roles } from "enums";

import useDocuments from "../hooks/useDocuments";
import { getPolicyHolder } from "../../../selectors";

const DOCUMENT_TO_SIGN = "VehicleDataOrSubjectChangeRequest";

export default function Documents({ previousStep }: { previousStep?: () => void }) {
    const { t } = i18n.useTranslation();
    const { documents, onComplete, href, idChange, isCompleteLoading, isDocumentsFetching } = useDocuments();

    const policyHolder = useSelector(getPolicyHolder);
    const isAuthenticated = useHasRole(Roles.AUTHENTICATED);

    const emailInfoText = isAuthenticated
        ? t("vehicle.change.vehicleData.documents.emailInfoAuthenticated", { email: policyHolder?.email })
        : t("vehicle.change.vehicleData.documents.emailInfoOneTime");

    const rows = documents
        .filter((document) => (isAuthenticated ? true : document.documentType !== DOCUMENT_TO_SIGN))
        .map((document) => {
            if (document.id) {
                return {
                    id: document.id,
                    typeLocalName: document.label ?? document.documentType,
                };
            }
        });

    const columns = [
        {
            field: "typeLocalName",
            headerName: `${t("documents.documentName")}`,
            flex: 2.5,
            sortable: false,
            renderCell: (params) => {
                return <DocumentLink fileName={params.value} fileUrl={href(idChange, params.row.id)} />;
            },
        },
        {
            field: "status",
            headerName: `${t("common.type")}`,
            flex: 1,
            sortable: false,
            renderCell: () => "PDF",
        },
    ];

    return (
        <>
            <Box textAlign={"center"} mb={3}>
                <Typography variant="h3">{t("vehicle.change.vehicleData.documents.title")}</Typography>
            </Box>

            <Box mb={8}>
                <Loading loading={isDocumentsFetching}>
                    <DataTable
                        disableColumnReorder
                        getRowId={(row) => row.id}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        rows={rows}
                        columns={columns}
                        hideFooter
                    />
                </Loading>
            </Box>

            <Box mb={6}>
                <Typography variant="body" markDown={emailInfoText} />
            </Box>

            <Grid container>
                <Grid item xs={6} textAlign={"start"}>
                    <CancelOrCloseRequestButton />
                </Grid>
                <Grid item xs={6} textAlign={"end"}>
                    <Button variant="contained" color="blue" onClick={onComplete}>
                        {isCompleteLoading ? <Loading loading /> : t("vehicle.change.vehicleData.button.submit")}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
